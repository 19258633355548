import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../../components/layout'
import Hero from '../../components/hero-image'
import HeroBar from '../../components/hero-bar'
import ProductOptions from '../../components/product-options'
import ProductFeatures from '../../components/product-features'

import ProductHero from './traditional-flush-window.jpg'

import WeatherSeals from './feature-dual-weather-seals-traditional.png'
import ToughenedGlass from './feature-24mm-glazing.png'

const TraditionalFlush = () => (
  <Layout>
    <Helmet title="Traditional Flush Windows | Traditional style | Rudd Joinery">
      <meta name="description" content="Our Traditional Flush Casement windows are designed with the replacement window market in mind. Manufactured using the very best quality engineered timber and quality hardware with a choice of Stays and Fasteners for the traditional cottage feel" />
    </Helmet>
    <Hero heroImage="traditional-flush-hero" heroId="hero" nextId="description">
      <h1>Traditional flush</h1>
      <h2>Traditional style</h2>
    </Hero>
    <div className="section" id="description">
      <div className="wrapper">
        <div
          className="story text-block"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <p>
            Our <strong>Traditional Flush</strong> casement windows from the{' '}
            <strong>Traditional Flush Range</strong> are designed with the
            replacement window market in mind. Manufactured using the very best
            quality engineered timber and quality hardware with a choice of
            Stays and Fasteners for the traditional cottage feel.
          </p>
          <p>
            Manufactured using cutting edge technology to meet our customers'
            expectations and requirements supplying superior quality
            and offering the highest level of performance.
          </p>
          <p>
            <img 
              src={ProductHero} 
              alt="Rudd Joinery | Traditional Storm" 
              className="product-hero"
              data-sal-duration="600"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            />
          </p>
        </div>
      </div>
    </div>
    <ProductFeatures>
      <div className="cta-bar sticky greenblue">
        <div className="expanded">
          <h3>Our traditional flush features</h3>
          <ul>
            <li>High performance weather seals</li>            
            <li>24mm toughened argon filled double glazing</li>
          </ul>
        </div>
      </div>      
      <div className="feature-bar">
        <div className="block c__1">
          <h2>High performance weather seals</h2>
          <p>
            Featuring high performance weather seals to further prevent weather ingress in
            exposed locations.
          </p>
        </div>
        <img src={WeatherSeals} alt="Dual weather seals" className="c__5"  />
      </div>
      <div className="feature-bar lightgrey">
        <div className="block c__1">
          <h2>24mm toughened double glazing</h2>
          <p>
            High energy efficiency is further increased by argon filled double
            glazing.
          </p>
        </div>
        <img src={ToughenedGlass} alt="24mm toughened double glazing" className="c__5" />
      </div>
      <div className="cta-bar greenblue">
        <div className="expanded">
          <p>
            Our <strong>Traditional Flush</strong> windows can be installed
            alongside our Stormproof windows and doors, as all our mouldings are
            complimented across our product range
          </p>
        </div>
      </div>
    </ProductFeatures>
    <HeroBar imageName="traditional-flush-hero" />
    <div className="section greenblue">
      <div className="wrapper">
        <div className="story text-block">
          <h2 className="heading">Traditional flush options</h2>
        </div>
        <ProductOptions ProductName="Traditional flush" />
      </div>
    </div>
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading">Traditional flush</h2>
          <p>
            Our bespoke <strong>Traditional Flush</strong> window can be
            manufactured to your specific requirements. If you need help, please
            do not hesitate to contact us on <strong>01485 529136</strong> or
            fill out our <Link to="/contact-us#contact-us">contact form</Link>.
          </p>          
          <p>
            <Link to="/contact-us" className="cta">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default TraditionalFlush